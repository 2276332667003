<template>
  <div class="videos-my-account">
    <component v-for="item in sortedElements" :is="itemComponent(item.entity)" :key="`${item.entity}_${item.uid}`"
      :tournament="item" :competition="item" />
    <!-- <Verdict v-for="verdict in verdicts" :key="verdict.id" :competition="verdict" />
    <Tournament v-for="tournament in tournamentsVerdicts" :key="`tr_${tournament.id}`" :tournament="tournament" /> -->
  </div>
</template>

<script>
import Verdict from '@/components/Competitions/Verdict'
import Tournament from '@/components/Competitions/Tournament'

import { getVerdicts } from '@/api/competition'
import { getTournamentsVerdicts } from '@/api/public/competition'

export default {
  name: 'VideosMyAccount',
  components: {
    Verdict,
    Tournament
  },
  data() {
    return {
      verdicts: [],
      tournamentsVerdicts: []
    }
  },
  created() {
    this.getVerdicts()
    this.getTournVerdicts()
  },
  computed: {
    sortedElements() {
      return [...this.tournamentsVerdicts, ...this.verdicts].sort((a, b) => {
        if (a.event_at) {
          if (b.event_at) {
            return (new Date(b.event_at) - new Date(a.event_at));
          } else {
            return (new Date(b.end_at) - new Date(a.event_at));
          }
        }

        if (a.end_at) {
          if (b.end_at) {
            return (new Date(b.end_at) - new Date(a.end_at));
          } else {
            return (new Date(b.event_at) - new Date(a.end_at));
          }
        }
      })
    },

  },
  methods: {
    async getVerdicts() {
      try {
        const { data: verdicts } = await getVerdicts()

        this.verdicts = verdicts.filter(el => el.event_type !== 'App\\Models\\TournamentEvent')
      } catch (e) {
        this.verdicts = []
      }
    },
    async getTournVerdicts() {
      try {
        const { data: verdicts } = await getTournamentsVerdicts()

        this.tournamentsVerdicts = verdicts.filter(el => el.competitors.find(el => el.id === this.$store?.state?.auth?.user?.id) && el.all_has_video_is_live)
      } catch (error) {
        console.log(error);
      }
    },
    itemComponent(type) {
      let component = 'verdict'
      switch (type) {
        case 'tournament':
          component = 'tournament'
          break;
        default:
      }
      return component
    },
  }
}
</script>

<style lang="stylus">
  .videos-my-account {
    .verdict-component {
      margin 0 0 16px 0

      &:last-child {
        margin 0
      }
    }  
  }
</style>
