<template>
  <div
      data-vue-name="RatingMyAccount"
      class="rating-my-account"
  >
    <ul class="rating-my-account__competition">
      <li
        v-for="competition in ratingCompetition"
        :key="competition.id"
        class="rating-my-account__competition-item"
      >
        <svg-icon
            size="size24x24"
            :name="`ico-${competition.name.toLocaleLowerCase()}`"
            :class-name="`ico-${competition.name.toLocaleLowerCase()}`"
        />
        <div class="rating-my-account__competition-name">{{ competition.name }}</div>
        <div class="rating-my-account__dies" :class="competition.color"># {{ competition.dies }}</div>
        <div class="rating-my-account__of-count">{{ competition.item2 }}</div>
        <svg-icon
            size="size20x20"
            :name="competition.arrow"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RatingMyAccount",
  data() {
    return {
      ratingCompetition: [
        {id: 2, name: 'Swimming', dies: 3, color: 'color-style1', item2: 'of 1.56M', arrow: 'ico-arrow-right-up'},
        {id: 3, name: 'Basketball', dies: 548, color: 'color-style2', item2: 'of 245K', arrow: 'ico-arrow-right-up'},
      ],
    }
  },
}
</script>

<style lang="stylus">
  .rating-my-account__competition-item {
    display flex
    align-items flex-end
    padding-bottom 9px
    margin-bottom:25px
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #D8D8D8;
  }
  .rating-my-account__competition-item svg {
    margin-right: 8px
  }
  .rating-my-account__competition-name {
    font-size: 16px;
    line-height: 160%;
  }
  .rating-my-account__dies {
    flex-grow 1
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    text-align right
    margin-right: 4px;

    &.color-style1 {
      color: #F2C94C;
    }
  }
  .rating-my-account__of-count {
    color: #7B8B97;
    margin-right: 10px;
  }
</style>
