<template>
  <view-container name="user">

    <view-header v-if='!isCurrentUser'>
      <custom-header class="view-header" :router-back="() => $goBack()">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft" />
          Back
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div data-vue-name="UserInfo" class="my-account">
        <div class="my-account__head">
          <div class="my-account__img">
            <a-avatar v-if="avatar" :src="avatar" :size="110">
              <!-- <a-icon slot="icon" type="user" /> -->
            </a-avatar>
            <div class="avatarEmpty" v-else-if="!avatar && (firstName || lastName)">
              {{ getFirstLetters() }}
            </div>
            <a-avatar v-else :src="avatar" :size="110">
              <a-icon slot="icon" type="user" />
            </a-avatar>
          </div>

          <div class="my-account__box">
            <div class="my-account__name">
              <span>{{ firstName }}</span>
              <span>{{ lastName }}</span>
            </div>
            <div class="my-account__user-name" v-if="nickname">«{{ nickname }}»</div>
            <div class="my-account__country-star-box">
              <div class="my-account__country">
                <img :src="countryIcon" class="my-account_ico-flag-jp" alt="" />
                <span>{{ countryCode }}</span>
              </div>
              <div class="my-account__star">
                <div>
                  <svg class="ico-star">
                    <use xlink:href="@/assets/img/svg-sprite.svg#icoStar"></use>
                  </svg>
                  <span> {{ rating }} </span>
                </div>
                <!-- <div v-if='isCurrentUser'>
                  <svg-icon size="size16x16" name="icoCoin" />
                  <span>{{ balance }}</span>
                  <RouterLink :to='{ name: "coins-storage", query: { back: this.$route.path } }'>
                    <svg class="ico-add-circle">
                      <use xlink:href="@/assets/img/svg-sprite.svg#icoAddCircle"></use>
                    </svg>
                  </RouterLink>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <span v-if="isCurrentUser" class="my-balance__title">My Balance</span>
        <div class="my-account__toolbar">
          <template v-if="isCurrentUser">
            <div class="toolbar__buttons">
              <div class="toolbar__coins">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM6.875 9.99341C6.875 9.24648 7.0237 8.59183 7.32109 8.02944C7.62723 7.45826 8.04707 7.01889 8.58063 6.71134C9.11419 6.40378 9.72646 6.25 10.4175 6.25C11.2921 6.25 12.0138 6.4609 12.5823 6.88269C13.1596 7.29569 13.5488 7.88884 13.75 8.66213H12.1362C12.005 8.30185 11.7951 8.02065 11.5064 7.81854C11.2178 7.61643 10.8548 7.51538 10.4175 7.51538C9.80518 7.51538 9.31536 7.73506 8.948 8.17443C8.58938 8.60501 8.41007 9.21134 8.41007 9.99341C8.41007 10.7755 8.58938 11.3862 8.948 11.8256C9.31536 12.2649 9.80518 12.4846 10.4175 12.4846C11.2834 12.4846 11.8563 12.1024 12.1362 11.3379H13.75C13.5401 12.076 13.1465 12.6648 12.5692 13.1041C11.9919 13.5347 11.2746 13.75 10.4175 13.75C9.72646 13.75 9.11419 13.5962 8.58063 13.2887C8.04707 12.9723 7.62723 12.533 7.32109 11.9706C7.0237 11.3994 6.875 10.7403 6.875 9.99341ZM17.5 10.0002C17.5 14.1423 14.1421 17.5002 9.99995 17.5002C5.85782 17.5002 2.49995 14.1423 2.49995 10.0002C2.49995 5.85805 5.85782 2.50019 9.99995 2.50019C14.1421 2.50019 17.5 5.85805 17.5 10.0002ZM18.5 10.0002C18.5 14.6946 14.6944 18.5002 9.99995 18.5002C5.30553 18.5002 1.49995 14.6946 1.49995 10.0002C1.49995 5.30577 5.30553 1.50019 9.99995 1.50019C14.6944 1.50019 18.5 5.30577 18.5 10.0002Z"
                    fill="#F3C129" />
                </svg>
                <span class="toolbar__balance">{{ balance }}</span>
              </div>
              <RouterLink :to='{ name: "coins-storage", query: { back: this.$route.path } }'
                class="my-account__btn-settings btn-style1">

                <svg-icon size="size20x20" name="icoPlus" />
              </RouterLink>
            </div>
            <div class="toolbar__buttons">
              <router-link to="/settings" class="my-account__btn-settings btn-style1">
                <svg-icon size="size20x20" name="icoSettings" />
              </router-link>
              <button class="my-account__btn-more-fill btn-style2" @click='copyLink'
                :class="copiedUrl ? 'chain--copied' : ''">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.8833 6.75845L12.0625 7.93761C12.6042 8.47929 13.0339 9.12238 13.3271 9.83015C13.6203 10.5379 13.7712 11.2965 13.7712 12.0626C13.7712 12.8287 13.6203 13.5873 13.3271 14.2951C13.0339 15.0028 12.6042 15.6459 12.0625 16.1876L11.7675 16.4818C10.6735 17.5758 9.18964 18.1904 7.64247 18.1904C6.09529 18.1904 4.61149 17.5758 3.51747 16.4818C2.42345 15.3878 1.80884 13.904 1.80884 12.3568C1.80884 10.8096 2.42345 9.3258 3.51747 8.23178L4.69664 9.41094C4.30694 9.79724 3.99739 10.2567 3.78574 10.7629C3.5741 11.2692 3.46453 11.8123 3.46333 12.361C3.46212 12.9097 3.56931 13.4532 3.77874 13.9604C3.98817 14.4676 4.29571 14.9284 4.6837 15.3164C5.0717 15.7044 5.53251 16.0119 6.03968 16.2213C6.54686 16.4308 7.0904 16.538 7.63911 16.5368C8.18781 16.5356 8.73088 16.426 9.23713 16.2143C9.74338 16.0027 10.2028 15.6931 10.5891 15.3034L10.8841 15.0084C11.6653 14.2271 12.1041 13.1675 12.1041 12.0626C12.1041 10.9578 11.6653 9.89814 10.8841 9.11678L9.70497 7.93761L10.8841 6.75928L10.8833 6.75845ZM16.4816 11.7676L15.3033 10.5893C15.693 10.203 16.0025 9.74352 16.2142 9.23727C16.4258 8.73103 16.5354 8.18796 16.5366 7.63925C16.5378 7.09054 16.4306 6.547 16.2212 6.03983C16.0118 5.53266 15.7042 5.07184 15.3162 4.68385C14.9282 4.29585 14.4674 3.98831 13.9603 3.77888C13.4531 3.56946 12.9095 3.46227 12.3608 3.46347C11.8121 3.46467 11.2691 3.57424 10.7628 3.78589C10.2566 3.99753 9.7971 4.30709 9.4108 4.69678L9.1158 4.99178C8.33467 5.77314 7.89586 6.83276 7.89586 7.93761C7.89586 9.04246 8.33467 10.1021 9.1158 10.8834L10.295 12.0626L9.1158 13.2409L7.93747 12.0626C7.39573 11.5209 6.966 10.8778 6.67281 10.1701C6.37962 9.4623 6.22871 8.70371 6.22871 7.93761C6.22871 7.17152 6.37962 6.41293 6.67281 5.70515C6.966 4.99738 7.39573 4.35429 7.93747 3.81261L8.23247 3.51845C9.32649 2.42443 10.8103 1.80981 12.3575 1.80981C13.9046 1.80981 15.3885 2.42443 16.4825 3.51845C17.5765 4.61246 18.1911 6.09627 18.1911 7.64345C18.1911 9.19062 17.5765 10.6744 16.4825 11.7684L16.4816 11.7676Z"
                    fill="#7B8B97" />
                </svg>
              </button>
            </div>
          </template>
          <template v-else>
            <!-- <a @click="goToNewCompetition" class="my-account__btn-settings btn-style1">
              <svg-icon size="size20x20" name="icoPlus" />
            </a> -->
            <a-button @click="goToNewCompetition" style="height: 46px;">
              <Icon :size="17" type="sword" />
              <span>Compete now</span>
            </a-button>
            <button class="my-account__btn-more-fill btn-style2" @click='copyLink'
              :class="copiedUrl ? 'chain--copied' : ''">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.8833 6.75845L12.0625 7.93761C12.6042 8.47929 13.0339 9.12238 13.3271 9.83015C13.6203 10.5379 13.7712 11.2965 13.7712 12.0626C13.7712 12.8287 13.6203 13.5873 13.3271 14.2951C13.0339 15.0028 12.6042 15.6459 12.0625 16.1876L11.7675 16.4818C10.6735 17.5758 9.18964 18.1904 7.64247 18.1904C6.09529 18.1904 4.61149 17.5758 3.51747 16.4818C2.42345 15.3878 1.80884 13.904 1.80884 12.3568C1.80884 10.8096 2.42345 9.3258 3.51747 8.23178L4.69664 9.41094C4.30694 9.79724 3.99739 10.2567 3.78574 10.7629C3.5741 11.2692 3.46453 11.8123 3.46333 12.361C3.46212 12.9097 3.56931 13.4532 3.77874 13.9604C3.98817 14.4676 4.29571 14.9284 4.6837 15.3164C5.0717 15.7044 5.53251 16.0119 6.03968 16.2213C6.54686 16.4308 7.0904 16.538 7.63911 16.5368C8.18781 16.5356 8.73088 16.426 9.23713 16.2143C9.74338 16.0027 10.2028 15.6931 10.5891 15.3034L10.8841 15.0084C11.6653 14.2271 12.1041 13.1675 12.1041 12.0626C12.1041 10.9578 11.6653 9.89814 10.8841 9.11678L9.70497 7.93761L10.8841 6.75928L10.8833 6.75845ZM16.4816 11.7676L15.3033 10.5893C15.693 10.203 16.0025 9.74352 16.2142 9.23727C16.4258 8.73103 16.5354 8.18796 16.5366 7.63925C16.5378 7.09054 16.4306 6.547 16.2212 6.03983C16.0118 5.53266 15.7042 5.07184 15.3162 4.68385C14.9282 4.29585 14.4674 3.98831 13.9603 3.77888C13.4531 3.56946 12.9095 3.46227 12.3608 3.46347C11.8121 3.46467 11.2691 3.57424 10.7628 3.78589C10.2566 3.99753 9.7971 4.30709 9.4108 4.69678L9.1158 4.99178C8.33467 5.77314 7.89586 6.83276 7.89586 7.93761C7.89586 9.04246 8.33467 10.1021 9.1158 10.8834L10.295 12.0626L9.1158 13.2409L7.93747 12.0626C7.39573 11.5209 6.966 10.8778 6.67281 10.1701C6.37962 9.4623 6.22871 8.70371 6.22871 7.93761C6.22871 7.17152 6.37962 6.41293 6.67281 5.70515C6.966 4.99738 7.39573 4.35429 7.93747 3.81261L8.23247 3.51845C9.32649 2.42443 10.8103 1.80981 12.3575 1.80981C13.9046 1.80981 15.3885 2.42443 16.4825 3.51845C17.5765 4.61246 18.1911 6.09627 18.1911 7.64345C18.1911 9.19062 17.5765 10.6744 16.4825 11.7684L16.4816 11.7676Z"
                  fill="#7B8B97" />
              </svg>
            </button>
          </template>
        </div>
        <div v-if="copiedUrl" class="my-account__msgs">
          <span>Link was copied</span>
        </div>
        <ul class="my-account__info">
          <li>
            <div class="my-account__info-title">Won</div>
            <div class="my-account__info-val">{{ win }}</div>
          </li>
          <li>
            <div class="my-account__info-title">Lost</div>
            <div class="my-account__info-val">{{ lose }}</div>
          </li>
        </ul>
        <div class="my-account__tabs">
          <tabs ref='tabs' :tabs="tabs" class="my-account__tabs-main-box" @click="selectedTabName" />
          <transition appear name="fade">
            <component :is="tabComponent" :user="user"></component>
          </transition>
        </div>
      </div>

      <NewCompetition :open="popup" @close="togglePopup" />

      <AccountPopupMore :open='isShowMenuPopup' @close='toggleMenuPopup' :user='user' />

    </view-content>

    <view-footer v-if='isCurrentUser'>
      <footer-competitions />
    </view-footer>
  </view-container>
</template>

<script>
import Tabs from '@/views/UserInfo/UserInfoTabs/UserInfoTabs'
import InfoMyAccount from '@/views/UserInfo/UserInfoTabs/InfoUserInfo'
import BtnGetPremium from '@/components/BtnGetPremium'
import NewCompetition from '@/components/Popup/NewCompetition'
import VideosMyAccount from '@/views/UserInfo/UserInfoTabs/VideosUserInfo'
import RatingMyAccount from '@/views/UserInfo/UserInfoTabs/RatingUserInfo'
import AchievementsMyAccount from '@/views/UserInfo/UserInfoTabs/AchievementsUserInfo'
import { mapState, mapGetters } from 'vuex'
import { copyTextToClipboard } from '@/helpers'
import Popup from '@/components/Popup'
import AccountPopupMore from '@/components/AccountPopupMore/AccountPopupMore'
import CommonButton from '@/components/UI/CommonButton'
import Icon from '@/components/Icon'

export default {
  name: 'UserInfo',
  components: {
    Tabs,
    Info: InfoMyAccount,
    Videos: VideosMyAccount,
    Rating: RatingMyAccount,
    Achievements: AchievementsMyAccount,
    BtnGetPremium,
    NewCompetition,
    Popup,
    AccountPopupMore,
    CommonButton,
    Icon
  },
  mounted() {
    if (this.$route.params.userID !== undefined) {
      this.$store.dispatch('getUserByID', this.$route.params.userID).then((res) => {
        this.user = res
      })
    } else if (this.currentUser) {
      this.user = this.currentUser
      this.$store.dispatch('getUser')
    }
  },
  computed: {
    ...mapGetters(['profileFilled', 'balance']),
    ...mapState({
      users: ({ competition }) => competition.users,
      currentUser: ({ auth }) => auth.user
    }),
    tabComponent() {
      return this.currentTabName
    },
    names() {
      return this.user?.name?.split(' ') || []
    },
    firstName() {
      return this.names[0] || ''
    },
    lastName() {
      return this.names[1] || ''
    },
    nickname() {
      return this.user?.nickname || null
    },
    rating() {
      return this.user?.rating
    },
    countryCode() {
      return this.user?.country?.code
    },
    countryIcon() {
      return this.user?.country?.icon
    },
    avatar() {
      return this.user?.photo
    },
    win() {
      return this.user?.win
    },
    lose() {
      return this.user?.lose
    },
    isCurrentUser() {
      return this.currentUser?.id === this.user?.id
    }
  },
  data() {
    return {
      tabs: [
        { id: 1, title: 'Videos' },
        { id: 2, title: 'Info' },
        { id: 3, title: 'Achievements' },
        { id: 4, title: 'Rating' },
      ],
      currentTabName: 'Videos',
      user: {},
      popup: false,
      isShowMenuPopup: false,
      copiedUrl: false
    }
  },
  watch: {
    '$route.hash'(value) {
      const key = value.replace('#tab-', '');
      this.$refs.tabs.selectedTab(key ? key : 'Videos')
    },
    currentUser(value) {
      if (this.$route.params.userID === undefined) {
        this.user = value
      }
    },
    '$route.params.userID'(userID) {
      if (userID !== undefined) {
        this.$store.dispatch('getUserByID', userID).then((res) => {
          this.user = res
        })
      } else if (this.currentUser) {
        this.user = this.currentUser
      }
    }
  },
  methods: {
    toggleMenuPopup() {
      this.isShowMenuPopup = !this.isShowMenuPopup
    },
    selectedTabName(name) {
      this.currentTabName = name
    },
    goToNewCompetition() {
      if (!this.currentUser) {
        this.$router.push({ name: 'authorization' })
        return;
      }
      this.profileFilled
        ? this.$router.push({ name: 'newcompetition' })
        : this.togglePopup()
    },
    togglePopup() {
      this.popup = !this.popup
    },
    copyLink() {
      copyTextToClipboard(`${window.location.origin}/account/${this.user.id}`)
      this.copiedUrl = true
      console.log(this.copiedUrl);
    },
    getFirstLetters() {
      if (!this.firstName && !this.lastName) {
        return ''
      }
      if (this.lastName) {
        return this.firstName[0] + this.lastName[0]
      }
      return this.firstName[0]
    }
  },
  async swipeUpdate() {
    if (this.$route.params.userID !== undefined) {
      await this.$store.dispatch('getUserByID', this.$route.params.userID).then((res) => {
        this.user = res
      })
    } else if (this.currentUser) {
      await this.$store.dispatch('getUser', this.$route.params.userID)
      this.user = this.currentUser
    }
  }
}
</script>

<style lang="stylus">
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.my-account__head {
  background: linear-gradient(180deg, rgba(38, 43, 63, 0) 0%, #262B3F 100%);
  border-radius: 20px;
  padding: 16px 16px;
  padding-top: 0px
  margin: 0 -16px 16px;
  display: flex;
}

.my-account__img {
  min-width: 110px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.my-account__box {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.my-account__name {
  font-family: Poppins;
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  color: #D8D8D8;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 8px;

  span:nth-child(1) {
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 100%;
  }
}

.my-account__user-name {
  color: #EB1E59;
  margin-bottom: 10px
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
}

.my-account__country-star-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-account__country {
  margin-right: 17px;
}

.my-account_ico-flag-jp {
  width: 17px;
  height: auto;
  margin-right: 4px;
}

.my-account__star {
  display: flex;
  align-items: center;
  & > div {
    display: flex
    align-items center
    &:first-child {
      margin-right:20px;
    }
  }
  span {
    margin: 0 4px;
  }
}

.my-account__toolbar {
  display: flex;
  flex-direction: row
  justify-content: space-between;
  margin-bottom: 16px;
}

.toolbar__buttons {
  display: flex;
  flex-direction: row
}

.toolbar__buttons > button {
  margin-left: 8px;
}

.toolbar__create-comp {
  width: 44px;
  height: 44px;
}

.toolbar__coins {
  display: flex
  align-items: center
  background: #1D202E;
  border-radius: 8px;
  padding: 12px 4px 12px 12px;
  min-width: 112px;
  margin-right: 8px;
}

.toolbar__balance {
  margin-left: 9.5px
}

.my-balance__title {
  color: #7B8B97;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.my-account__btn-add-new-competition {
  font-size: 16px;
  line-height: 140%;
  display: flex;
}

.my-account__btn-settings, .my-account__btn-more-fill {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
}

.my-account__btn-more-fill:hover {
  background: #d21341;
  color: white;
  path {
    fill: white;
  }
}

.chain--copied {
  path {
    fill: green;
  }
}

.my-account__info {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 35px;
  background: #1D202E;
  border-radius: 8px;
  padding: 8px 0px;
}

.my-account__info li {
  cursor: pointer;
  // min-width: 45px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-account__info > li:first-child {
  border-right: 1px solid #3D445C;
}

.my-account__info-title {
  font-size: 12px;
  color: #7B8B97;
}

.my-account__info-val {
  line-height: 140%;
  font-size: 24px;
  color: #D8D8D8;
}

.my-account__tabs-main-box .tabs-box .tabs-box__name {
  flex-grow: 1;
}
.start-storage__stars {
  font-size: 14px;
  line-height: 140%;
  color: #D8D8D8;
  display inline-flex
  align-items center
  svg {
    margin-right:5px
  }
}

.test {
  background: #D21341
}

.my-account__msgs {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  span {
    display: block;
    padding-bottom: 5px
  }
}

.avatarEmpty {
  text-align: center;
  vertical-align: baseline;
  width: 120px;
  height: 120px;
  background: #D8D8D8;
  color: #2F354D;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 60px;
  border-radius: 50%;
  padding: 30px 0;
}
</style>
