<template>
  <div data-vue-name="InfoMyAccount" class="info-my-account">
    <ul class="info-my-account__info-data">
      <li v-for="item in infoData" :key="item.id">
        <div class="info-my-account__field-name">{{ item.name }}</div>
        <div class="info-my-account__filed-val">{{ item.val }}</div>
      </li>
    </ul>

    <div class="info-my-account__featured">
      <div class="sub-title">Featured in</div>
      <ul>
        <li>
          <svg-icon
            size="size24x24"
            class-name="ico-swimming"
            name="ico-swimming"
          />
          <div class="info-my-account__name">Swimming</div>
          <div class="info-my-account__won"><span>Won</span>56</div>
          <div class="info-my-account__lost"><span>Lost</span>3</div>
        </li>
         <li>
          <svg-icon
            size="size24x24"
            class-name="ico-basketball"
            name="ico-basketball"
          />
          <div class="info-my-account__name">Basketball</div>
          <div class="info-my-account__won"><span>Won</span>44</div>
          <div class="info-my-account__lost"><span>Lost</span>2</div>
        </li>
      </ul>
    </div>
    <div class="info-my-account__featured">
      <div class="sub-title">Bio</div>
      <div class="info-my-account__text-box">
        <div v-if="!isCurrentUser">{{ user.description }}</div>
        <div v-show="!isEdit && isCurrentUser">{{ userCurrent && userCurrent.description }}</div>
        <textarea
          v-if="isEdit"
          v-model="userData.description"
          class="info-my-account__text"
        >
        </textarea>
      </div>
      <template v-if="isCurrentUser">
        <button v-if="!isEdit" class="btn-icon-t1" @click="btnEdit">
          <svg-icon size="size20x20" :name="'icoEdit'"/>
          Edit
        </button>
        <button
            v-if="isEdit"
            class="btn-icon-t1"
            @click="handleUserDescriptionUpdate"
        >
          <svg-icon size="size20x20" :name="'icoEdit'"/>
          Save
        </button>
      </template>
    </div>
  </div>
</template>

<script>

import moment from 'moment-timezone'
import { mapState } from 'vuex'

export default {
  name: 'InfoMyAccount',
  props: ['user'],
  computed: {
    ...mapState({
      userCurrent: ({ auth }) => auth.user,
      users: ({ competition }) => competition.users,
    }),
    isCurrentUser() {
      if (!this.userCurrent) {
        return false
      }
      return this.user.id === this.userCurrent.id
    },
    userDescription() {
      if (this.userData.description) {
        return this.userData.description
      } else if (this.userCurrent.description) {
        return this.userCurrent.description
      } else {
        return '';
      }
    },
    regDate() {
      const date = this.user?.created_at

      if (!date) {
        return null
      }

      return moment(date).format('DD.MM.YYYY')
    },
    infoData() {
      return [
        { id: 1, name: 'Age', val: this.getAgeByData },
        {
          id: 2,
          name: 'Height',
          val: `${this.user?.height ? this.user?.height : 0} ${this.heightByMetrics}`,
        },
        {
          id: 3,
          name: 'Weight',
          val: `${this.user?.weight ? this.user?.weight : 0} ${this.weightByMetrics}`,
        },
        {
          id: 4,
          name: 'Gender',
          val: `${(this.user.gender) ? this.user.gender.charAt(0).toUpperCase() + this.user.gender.slice(1) : ''}`,
        },
        { id: 5, name: 'Ratio'},
        { id: 6, name: 'Reg.', val: this.regDate },
      ]
    },
    heightByMetrics() {
      return this.$store.getters.valueByMetrics ? this.$store.getters.valueByMetrics?.height : ''
    },
    weightByMetrics() {
      return this.$store.getters.valueByMetrics ? this.$store.getters.valueByMetrics?.weight : ''
    },
    getAgeByData() {
      const birthday = new Date(this.user?.birthday)
      const ageDiff = Date.now() - birthday
      const ageDate = new Date(ageDiff)
      const age = Math.abs(ageDate.getUTCFullYear() - 1970)
      return (isNaN(age)) ? 0 : age
    },
  },
  data() {
    return {
      isEdit: false,
      userData: {
        description: '',
      },
    }
  },
  methods: {
    btnEdit() {
      if (this.userDescription) {
        this.userData.description = this.userDescription
      }
      this.isEdit = !this.isEdit
    },
    handleUserDescriptionUpdate() {
      this.$store.dispatch('updateUser', this.userData).then((res) => {
        this.userData.description = res.description
        console.log(this.userData.description);
        this.isEdit = !this.isEdit
      })
    },
  },
}
</script>

<style lang="stylus">
.info-my-account__info-data {
  margin-bottom 24px
}
.info-my-account__info-data li {
  display flex
  width 200px
  justify-content space-between
  margin-bottom 12px
}
.info-my-account__field-name {
  margin-right 15px
  color: #7B8B97;
}
.info-my-account__filed-val {
  text-align right
}
.info-my-account__featured {
  margin-bottom 25px
}

.info-my-account__featured li {
  display flex
  justify-content space-between
  margin-bottom 10px
}
.info-my-account__featured li svg {
  margin-right 8px
}
.info-my-account__name, .info-my-account__won, .info-my-account__lost {
  font-size: 16px;
  line-height: 160%;
  margin-right auto
}
.info-my-account__won, .info-my-account__lost {
  margin-left 16px
  margin-right 0
}
.info-my-account__won span, .info-my-account__lost span {
  font-size: 12px;
  line-height: 140%;
  color: #7B8B97;
  padding-right 4px
}
.info-my-account__text-box {
  overflow hidden
  width 100%
  font-size: 16px;
  line-height: 160%;
  color: #D8D8D8;
  border-radius 12px
  margin-bottom 8px
}
.info-my-account__text {
  width calc(100% + 17px)
  resize none
  height 260px
  font-size: 16px;
  line-height: 160%;
  color: #D8D8D8;
}
</style>
