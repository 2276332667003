<template>
  <div data-vue-name="AchievementsMyAccount" class="achievements-my-account">
    <div class="achievements-my-account__honors">
      <div class="sub-title">Honors</div>
      <ul class='medals-list'>
        <li>
          <img :src="require(`@/assets/img/gold-medal.svg`)" alt="" />
          <span>{{user.count_golden_medals}}</span>
        </li>
        <li>
          <img :src="require(`@/assets/img/silver-medal.svg`)" alt="" />
          <span>{{user.count_silver_medals}}</span>
        </li>
        <li>
          <img :src="require(`@/assets/img/bronze-medal.svg`)" alt="" />
          <span>{{user.count_bronze_medals}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AchievementsMyAccount',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    tabComponent() {
      return this.currentTabName
    },
  },
  data() {
    return {
      honors: [
        { id: 1, title: 'icoHonorTop10' },
        { id: 2, title: 'icoHonorFlash' },
        { id: 3, title: 'icoHonorTop100' },
        { id: 4, title: 'icoHonorAdorable' },
        { id: 5, title: 'icoHonorFriendly' },
      ],
      tabs: [
        { id: 1, title: 'Unlocked', quantity: 6 },
        { id: 2, title: 'Ongoing' },
      ],
      currentTabName: 'Unlocked',
    }
  },
  methods: {
    selectedTabName(name) {
      this.currentTabName = name
    },
  },
}
</script>

<style lang="stylus">
.achievements-my-account__honors {
  margin-bottom 24px
}
.medals-list {
  & > li {
    display flex
    align-items center
    margin-bottom 15px
    & > img {
      margin-right 3px
    }
    & > span {
      &:before {
        content ' — '
        margin-right 5px
      }
    }
    &:last-child {
      margin-bottom: 0
    }
  }
}
.achievements-my-account__achievement {
  padding-bottom 60px
}
</style>
