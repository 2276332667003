<template>
  <div
      data-vue-name="Tabs"
      class="tabs"
  >
    <ul class="tabs-box">
      <li
          v-for="tab in tabs"
          :key="tab.id"
          class="tabs-box__name"
          :class="{'tabs-box__name--active': currentTab === tab.title}"
          @click="selectedTab(tab.title)"
      >
        {{ tab.title }}
        <template v-if="tab.quantity > 0">
          ({{ tab.quantity }})
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "UserInfoTabs",
  props: {
    tabs: {
      type: Array,
      default: null,
    },
    quantity: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentTab: this.tabs[0].title,
    }
  },
  mounted() {
    if (this.$route.hash!==undefined && /^#tab-.+$/.test(this.$route.hash)) {
      const tabKey = this.$route.hash.split('-')[1];
      const decodeTabKey = decodeURI(tabKey);
      if (this.checkTabExist(decodeTabKey)) {
        this.selectedTab(decodeTabKey);
      }
    }
  },
  methods: {
    checkTabExist(tabKey) {
      return this.tabs.find(item => item.title===tabKey) !== undefined
    },
    selectedTab(name) {
      this.currentTab = name
      this.$emit('click', name)
    }
  },
  watch: {
    currentTab(value) {
      const routeWithHash = `${this.$route.path}#tab-${encodeURI(value)}`;
      if (this.$route.fullPath !== routeWithHash) {
        this.$router.push(routeWithHash)
      }
    }
  }
}
</script>

<style lang="stylus">

</style>
